export default class InputGetUsers
{
  constructor( pageNumber, pageSize, searchText, orderBy, propertyName, active ) 
  {
    this.pageNumber = pageNumber;
    this.pageSize = pageSize;
    this.searchText = searchText;
    this.orderBy = orderBy;
    this.propertyName = propertyName;
    this.active = active;
  }    
}