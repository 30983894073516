<template>
  <section>
    <div class="div-menu d-flex align-items-center">
        <div class="d-flex justify-content-end w-100 px-4">
            <!-- Alerts -->
            <router-link :to="{ name: 'AlertsList' }"><img src="../assets/menu/iconBell.png" alt="Home"></router-link>
            <!-- Logout -->
            <a @click="logoutRequested"><img src="../assets/menu/iconOut.png" alt="Home"></a>
        </div>
    </div>
  </section>
</template>

<script>
import { mapActions } from 'vuex';
import EventBus from '@/events/bus.js';

export default {
    methods:{
        ...mapActions('account', ['logout']),
        logoutRequested: function()
        {
            EventBus.$emit("warning-alert-requested", { message: `Tem a certeza que pretende terminar sessão?`, primaryCallback: () =>
            {
                this.logout();
            }, secondaryButton: "Cancelar"});
        }
    }
}
</script>

<style scoped>
.div-menu
{
    background-color: rgb(78, 84, 200) !important;
    height: 55px;
    width: 100vw; 
    overflow: hidden; 
    position: fixed;
    top: 0;
    z-index: 99;
}

.div-menu > div
{
    gap: 16px;
}

img
{
    width: 3em;
    padding: 10px;
}

img:hover,
a.router-link-exact-active>img
{
    background-color: rgba(143, 148, 251, 1);
    border-radius: 10px;
}
</style>