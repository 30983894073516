<template>
  <section>
    <div class="div-menu py-5">
        <div class="container d-flex flex-column h-100 justify-content-between align-items-center">
            <div id="menu-img-container" class="row justify-content-center">
                <img src="../assets/menu/house.png" alt="House" id="house-img">
            </div>
            <!-- Dashboard -->
            <div class="row btn-height">
                <router-link :to="{ name: 'Dashboard' }"><img src="../assets/menu/iconHome.png" alt="Home"></router-link>
            </div>
            <!-- Properties -->
            <div class="row btn-height">
                <router-link :to="{ name: 'PropertiesList' }"><img src="../assets/menu/iconImo.png" alt="Home"></router-link>
            </div>
            <!-- Statistics -->
            <div class="row btn-height">
                <router-link :to="{ name: 'Statistics' }"><img src="../assets/menu/icongraph.png" alt="Home"></router-link>
            </div>
            <!-- Users -->
            <div class="row btn-height">
                <router-link :to="{ name: 'UsersList' }"><img src="../assets/menu/iconPeople.png" alt="Home"></router-link>
            </div>
            <!-- Alerts -->
            <div class="row btn-height">
                <router-link :to="{ name: 'AlertsList' }"><img src="../assets/menu/iconBell.png" alt="Home"></router-link>
            </div>
            <!-- Logout -->
            <div class="row btn-height">
                <a @click="logoutRequested"><img src="../assets/menu/iconOut.png" alt="Home"></a>
            </div>
        </div> 
    </div>
  </section>
</template>

<script>
import { mapActions } from 'vuex';
import EventBus from '@/events/bus.js';

export default {
    methods:{
        ...mapActions('account', ['logout']),
        logoutRequested: function()
        {
            EventBus.$emit("warning-alert-requested", { message: `Tem a certeza que pretende terminar sessão?`, primaryCallback: () =>
            {
                this.logout();
                this.$router.push({name: 'Login'});
            }, secondaryButton: "Cancelar"});
        }
    }
}
</script>

<style scoped>
.div-menu{
    background-color: rgb(78, 84, 200) !important;
    height: 100vh; /* 100% Full-height */
    width: 250px; 
    overflow-x: hidden; /* Disable horizontal scroll */
    overflow-y: hidden !important;
    border-top-right-radius: 30px;
    border-bottom-right-radius: 30px;
    position: fixed;
}
#house-img{
    margin: 3rem 0 0 0;
    width: 8em;
    height: 65%;
}
a>img{
    width: 3em;
    padding: 10px;
}
a>img:hover,
a.router-link-exact-active>img{
    background-color: rgba(143, 148, 251, 1);
    border-radius: 10px;
}

@media only screen and (max-height: 850px) {
  #menu-img-container
  {
    display: none;
  }
}

@media only screen and (max-height: 400px) {
    .container
    {
        flex-direction: row !important;  
    }

    a>img{
        width: 2em;
        padding: 6px;
    }
}
</style>