<template>
  <section>
    <div class="c-app">
      <SideMenu class="d-none d-lg-block"/>
      <CWrapper>
        <SocialChat/>
        <div class="c-body">
          <main class="c-main">
            <TopMenu class="d-block d-lg-none"/>
            <CContainer fluid>
              <transition name="fade">
                <router-view/>
              </transition>
            </CContainer>
            <BottomMenu class="d-block d-lg-none"/>
          </main>
        </div>
      </CWrapper>
    </div>
  </section>
</template>

<script>
import SideMenu from "./SideMenu.vue";
import BottomMenu from "./BottomMenu.vue";
import TopMenu from "./TopMenu.vue";
import SocialChat from "@/controls/SocialChat.vue";
export default {
    name: "Menu",
    components: {
    SideMenu,
    BottomMenu,
    TopMenu,
    SocialChat
}
}
</script>

<style scoped>
.container-fluid
{
  background-color: rgb(239, 239, 239);
  min-height: 100vh;
}
@media only screen and (max-width: 991px) {
  .container-fluid
  {
    padding-top: 55px;
    padding-bottom: 100px;
  }
}

@media only screen and (min-width: 992px) {
  .c-main {
      padding-left: 260px ;
  }
}
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.3s;
}
.fade-enter,
.fade-leave-to {
  opacity: 0;
}
</style>