<template>
    <SocialChat
        id="social-button"
        icon
        :attendants="attendants"
    >
        <p slot="header">Precisa de ajuda? Escolha em baixo a pessoa com quem pretende conversar no WhatsApp.</p>
        <template v-slot:button>
            <img
                src="https://raw.githubusercontent.com/ktquez/vue-social-chat/master/src/icons/whatsapp.svg"
                alt="icon whatsapp"
                aria-hidden="true"
            >      
        </template>
    </SocialChat>
</template>

<script>
import { mapState, mapActions } from 'vuex';
import InputGetUsers from "@/classes/InputGetUsers";

export default {
    mounted () {
        //Make request
        const currentPage = 1;
        const itemsPerPage = 999;
        const searchText = "";
        const orderBy = 1;
        const propertyName = "id";
        const active = true;

        var input = new InputGetUsers(currentPage, itemsPerPage, searchText, orderBy, propertyName, active);
        this.listUsers(input);
    },
    methods: {
        ...mapActions('account', ['listUsers']),  
    },
    computed: {
        ...mapState('account', ['listOutput']),

        usersInfo: function() {
            return this.listOutput && this.listOutput.data ? this.listOutput.data : null;
        },
        users() {
            return this.usersInfo && this.usersInfo.users && this.usersInfo.users.length ? this.usersInfo.users : null
        },
        attendants() {
            let users = this.users;
            let result = [];
                if(this.users) {
                    for(let i = 0; i < users.length; i++) {
                        users[i].app = 'whatsapp';
                        users[i].number = users[i].phoneNumber
                        users[i].avatar = {
                            src: 'https://avatars0.githubusercontent.com/u/8084606?s=460&u=20b6499a416cf7129a18e5c168cf387e159edb1a&v=4',
                            alt: 'Alan Ktquez avatar'
                        };
                        result.push(users[i]);
                    }
                }
            return result;
        }
        
    }
}
</script>

<style scoped>
    #social-button {
        --vsc-bg-header: #46c056;
        --vsc-bg-footer: #FAFAFA;
        --vsc-text-color-header: white;
        --vsc-text-color-footer: #289D37;
        --vsc-bg-button: #46c056;
        --vsc-text-color-button: #289D37;
        --vsc-outline-color: #46c056;
        --vsc-border-color-bottom-header: #289D37;
        --vsc-border-color-top-footer: #FAFAFA;
    }
    .vsc-popup-header p {
        font-weight: 700;
    }
    @media only screen and (max-width: 991px) {
        >>>.vsc-popup {
            bottom: 115px;
        }
    }
</style>